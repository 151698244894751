import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { getSessionAuth, sessionLogout } from "components/shared/session";
import PropTypes from "prop-types";
import cn from "classnames";

// eslint-disable-next-line no-unused-vars
const logout = () => {
  sessionLogout();
};

export const Header = ({ margin }) => {
  const { user } = getSessionAuth();

  const [menuDisplay, setDisplay] = useState("0%");

  const setMenuDisplay = (menuDisplay) => {
    if (menuDisplay === "0%") {
      return "100vh";
    }
    if (menuDisplay === "100vh") {
      return "0%";
    }
  };

  return (
    <div
      className={cn({
        "member-header": true,
        "progress-margin": margin === "margin",
      })}
    >
      <div className="login">
        {(user && (
          <div>
            Welcome |{" "}
            <a
              href="https://waterscenterst.org/login"
              onClick={() => {
                logout();
              }}
            >
              Log Out
            </a>
          </div>
        )) || (
          <div id="login">
            <span>
              <a href="https://waterscenterst.org/login">Login</a>
            </span>
            <a href="https://waterscenterst.org/join">
              <button className="blue">Sign Up</button>
            </a>
          </div>
        )}
      </div>
      <div id="logo-container">
        <NavLink to={user ? "/dashboard" : "/"}>
          <img
            src="images/site/tts-logo.svg"
            alt="Thinking Tools Studio"
            id="logo"
          />
        </NavLink>
      </div>

      <button
        id="mobile-menu-toggle"
        onClick={() => setDisplay(setMenuDisplay(menuDisplay))}
      >
        <hr />
        <hr />
        <hr />
      </button>

      <div id="mobile-menu" style={{ height: menuDisplay }}>
        <ul id="mobile-menu-sections">
          <li>
            <ul>
              <li>
                <h4>
                  <NavLink
                    to="/courses/overview"
                    onClick={() => setDisplay(setMenuDisplay(menuDisplay))}
                  >
                    Courses
                  </NavLink>
                </h4>
              </li>

              <li>
                <NavLink
                  to="/courses/habits"
                  onClick={() => setDisplay(setMenuDisplay(menuDisplay))}
                >
                  Habits Courses
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/courses/tools"
                  onClick={() => setDisplay(setMenuDisplay(menuDisplay))}
                >
                  Tools Courses
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/courses/archetypes"
                  onClick={() => setDisplay(setMenuDisplay(menuDisplay))}
                >
                  Archetypes Courses
                </NavLink>
              </li>
            </ul>
          </li>

          <li>
            <ul>
              <li>
                <h4>
                  <NavLink
                    to="/resources/catalog"
                    onClick={() => setDisplay(setMenuDisplay(menuDisplay))}
                  >
                    Resources
                  </NavLink>
                </h4>
              </li>

              {/* <li>
                <NavLink
                  to="/resources/templates"
                  onClick={() => setDisplay(setMenuDisplay(menuDisplay))}
                >
                  Templates
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/resources/guides"
                  onClick={() => setDisplay(setMenuDisplay(menuDisplay))}
                >
                  Facilitation Guides
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/resources/lessons"
                  onClick={() => setDisplay(setMenuDisplay(menuDisplay))}
                >
                  Lesson Plans
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/resources/assessments"
                  onClick={() => setDisplay(setMenuDisplay(menuDisplay))}
                >
                  Assessments
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/resources/webinars"
                  onClick={() => setDisplay(setMenuDisplay(menuDisplay))}
                >
                  Webinars & Videos
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/resources/articles"
                  onClick={() => setDisplay(setMenuDisplay(menuDisplay))}
                >
                  Articles
                </NavLink>
              </li> */}
            </ul>
          </li>

          {/* <li>
            <ul>
              <li>
                <h4>Events</h4>
              </li>

              <li>
                <NavLink to='/events/openstudio' onClick={() => setDisplay(setMenuDisplay(menuDisplay))}>Open Studio</NavLink>
              </li>

              <li>
                <NavLink to='/events/teacherstudio' onClick={() => setDisplay(setMenuDisplay(menuDisplay))}>Teacher Studio</NavLink>
              </li>

              <li>
                <NavLink to='/events/studioseries' onClick={() => setDisplay(setMenuDisplay(menuDisplay))}>Studio Series</NavLink>
              </li>

              <li>
                <NavLink to='/events/virtualforums' onClick={() => setDisplay(setMenuDisplay(menuDisplay))}>Virtual Forums</NavLink>
              </li>
            </ul>
          </li> */}

          <li>
            <ul>
              <li>
                <NavLink
                  to="/events/overview"
                  onClick={() => setDisplay(setMenuDisplay(menuDisplay))}
                >
                  Live Online Learning
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/cards"
                  onClick={() => setDisplay(setMenuDisplay(menuDisplay))}
                >
                  Habits Cards
                </NavLink>
              </li>

              <li>
                <a
                  href="https://waters-center-for-systems-thinking.myshopify.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Shop
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <ul className="main-menu">
        <li className="has-dd">
          <NavLink to="/courses/overview">Courses</NavLink>
          <ul>
            <NavLink to="/courses/habits">
              <li>Habits Courses</li>
            </NavLink>

            <NavLink to="/courses/tools">
              <li>Tools Courses</li>
            </NavLink>

            <NavLink to="/courses/archetypes">
              <li>Archetypes Courses</li>
            </NavLink>
          </ul>
        </li>

        <li className="has-dd">
          <NavLink to="/resources/catalog">Resources</NavLink>
        </li>

        <li>
          <NavLink to="/cards">Habits Cards</NavLink>
        </li>

        <li>
          <NavLink to="/events/overview">Live Online Learning</NavLink>
        </li>

        <li>
          <a
            href="https://waters-center-for-systems-thinking.myshopify.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Shop
          </a>
        </li>
      </ul>
    </div>
  );
};
Header.propTypes = {
  margin: PropTypes.string,
};
