import * as React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

export const Footer = ({ margin }) => {
  return (
    <footer className={margin === "margin" ? "progress-margin" : ""}>
      <ul id="footer-menu">
        <li>
          <ul className="footer-column">
            <li>
              <h4>
                <NavLink to="/courses/overview">Courses</NavLink>
              </h4>
            </li>

            <li>
              <NavLink to="/courses/habits">Habits Courses</NavLink>
            </li>

            <li>
              <NavLink to="/courses/tools">Tools Courses</NavLink>
            </li>

            <li>
              <NavLink to="/courses/archetypes">Archetypes Courses</NavLink>
            </li>
          </ul>
        </li>

        <li>
          <ul className="footer-column">
            <li>
              <h4>
                <NavLink to="/resources/catalog">Resources</NavLink>
              </h4>
            </li>

            {/* <li>
              <NavLink to="/resources/templates">Templates</NavLink>
            </li>

            <li>
              <NavLink to="/resources/guides">Facilitation Guides</NavLink>
            </li>

            <li>
              <NavLink to="/resources/lessons">Lesson Plans</NavLink>
            </li>

            <li>
              <NavLink to="/resources/assessments">Assessments</NavLink>
            </li>

            <li>
              <NavLink to="/resources/webinars">Webinars & Videos</NavLink>
            </li>

            <li>
              <NavLink to="/resources/articles">Articles</NavLink>
            </li> */}
          </ul>
        </li>

        <li>
          <ul className="footer-column">
            <li>
              <h4>
                <NavLink to="/events/overview">Live Online Learning</NavLink>
              </h4>
            </li>

            <li>
              <a
                href="https://waterscenterst.org/webinars"
                target="_blank"
                rel="noopener noreferrer"
              >
                Webinars
              </a>
            </li>

            {/* <li>
              <NavLink to='/events/teacherstudio'>Teacher Studio Webinars</NavLink>
            </li> */}

            <li>
              <a
                href="https://waterscenterst.org/workshops"
                target="_blank"
                rel="noopener noreferrer"
              >
                Online Workshops
              </a>
            </li>

            {/* <li>
              <a
                href="https://waterscenterst.org/forums"
                target="_blank"
                rel="noopener noreferrer"
              >
                Virtual Forums
              </a>
            </li> */}

            <li>
              <a
                href="https://wcstcredential.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Credential Program
              </a>
            </li>
          </ul>
        </li>

        <li>
          <ul className="footer-column">
            <li>
              <NavLink to="/cards">Habits Cards</NavLink>
            </li>

            <li>
              <NavLink to="/support">Support</NavLink>
            </li>

            <li>
              <NavLink to="/features">Features</NavLink>
            </li>

            {/* <li>
              <NavLink to='/benefits'>Benefits</NavLink>
            </li>

            <li>
              <NavLink to='/about'>About the Waters Center</NavLink>
            </li> */}

            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>

            <li>
              <NavLink to="/donate">Donate</NavLink>
            </li>
          </ul>
        </li>
      </ul>

      <section>
        <div>
          <span>Powered by</span>
          <a
            className="footer-wc-logo"
            href="https://www.waterscenterst.org"
            target="blank"
            rel="noopener"
          />
        </div>
        <div>
          <ul id="social">
            <li>
              <a
                className="facebook"
                href="https://facebook.com/WatersCenterST"
                target="blank"
                rel="noopener"
              />
            </li>
            <li>
              <a
                className="twitter"
                href="https://twitter.com/WatersCenterST"
                target="blank"
                rel="noopener"
              />
            </li>
            <li>
              <a
                className="youtube"
                href="https://www.youtube.com/channel/UCAulFBVyAtTYmmxYL_KgZUw"
                target="blank"
                rel="noopener"
              />
            </li>
            <li>
              <a
                className="linkedin"
                href="https://www.linkedin.com/company/waterscenterforsystemsthinking/"
                target="blank"
                rel="noopener"
              />
            </li>
          </ul>
        </div>
        <div>
          <ul className="corner-menu">
            {/* <li><a href="/sitemap">Sitemap</a></li> */}
            <li>
              <NavLink to="/our-privacy-policy">Privacy Policy</NavLink>
            </li>
            <li>
              <NavLink to="/terms">Terms of Use</NavLink>
            </li>
          </ul>
          <span>
            Copyright © {new Date().getFullYear()} Thinking Tools Studio. All
            rights reserved
          </span>
        </div>
      </section>
    </footer>
  );
};
Footer.propTypes = {
  margin: PropTypes.string,
};
