import * as React from "react";
import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { answersPageStart } from "components/shared/answers/answers.actions";
import { ResourcesHome } from "./resourcesHome";
import { ResourceCatalog } from "components/resources/resource-catalogs";
import { ResourceLayout } from "./ResourceLayout";
import { resourceList } from "./resourceList";
import "./styles.scss";
import PropTypes from "prop-types";

const ResourcesRouterView = ({ match, onLoad }) => {
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <main>
      <Switch>
        <Route path="/resources/overview" component={ResourceCatalog} />
        <Route path="/resources/catalog" component={ResourceCatalog} />
        <Route path="/resources/templates" component={ResourceCatalog} />
        <Route path="/resources/guides" component={ResourceCatalog} />
        <Route path="/resources/lessons" component={ResourceCatalog} />
        <Route path="/resources/assessments" component={ResourceCatalog} />
        <Route path="/resources/webinars" component={ResourceCatalog} />
        <Route path="/resources/articles" component={ResourceCatalog} />
        {resourceList.map((r) => (
          // create route for every resource path (e.g. /resources/{resource-path})
          // url parts after the resource path will be handled by the Switch in the ResourceLayout
          <Route
            path={`${match.url}/${r.path}`}
            key={r.path}
            render={(props) => <ResourceLayout {...props} resource={r} />}
          />
        ))}
        {/* if no resource is found, redirect to first one, for now. TODO: handle better? */}
        <Redirect to={`${match.url}/catalog`} />
        {/* <Redirect to={`${match.url}/${resourceList[0].path}`} /> */}
      </Switch>
    </main>
  );
};

ResourcesRouterView.propTypes = {
  resource: PropTypes.object,
  match: PropTypes.any,
  onLoad: PropTypes.func,
};

export const ResourcesRouter = connect(
  ({ answers }, ownProps) => ({
    ...ownProps,
    isLoading: answers.isLoading,
  }),
  (dispatch) => ({
    onLoad: () => dispatch(answersPageStart()),
  }),
)(ResourcesRouterView);
